import React from "react";
import { useState } from "react";
import LayoutBox from "./LayoutBox";
import theme from "../config/theme.json";
import { useAuth } from "../context/AuthContext";
import { sendEmail } from "../api/email";

const RequestCodeModal = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [error, setError] = useState("");
  const { cancelRequestCode, login } = useAuth();

  const handleSubmit = () => {
    if (name && email && company) {
      sendEmail(name, email, company, "Access Code Request");
      cancelRequestCode();
    } else {
      setError("All fields are required.");
    }
  };

  const handleCancel = () => {
    cancelRequestCode();
  };

  return (
    <>
      <div className="dim" onClick={handleCancel} />
      <div className="modal">
        <LayoutBox title="Request Access Code">
          <input
            type="text"
            placeholder="Your Name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              borderColor: theme.borderColor,
              backgroundColor: theme.lighterBackgroundColor,
              color: theme.textColor,
            }}
          />
          <input
            type="text"
            placeholder="Your Email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              borderColor: theme.borderColor,
              backgroundColor: theme.lighterBackgroundColor,
              color: theme.textColor,
            }}
          />
          <input
            type="text"
            placeholder="Company Name"
            id="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            style={{
              borderColor: theme.borderColor,
              backgroundColor: theme.lighterBackgroundColor,
              color: theme.textColor,
            }}
          />
          {error && <span className="error">{error}</span>}
          <div className="button-bar-row" style={{ textAlign: "center" }}>
            <div className="button-bar" style={{ borderColor: theme.colorB }}>
              <a style={{ borderColor: theme.colorB }} onClick={handleSubmit}>
                Submit
              </a>
              <a style={{ borderColor: theme.colorB }} onClick={handleCancel}>
                Cancel
              </a>
            </div>
          </div>
        </LayoutBox>
      </div>
    </>
  );
};

export default RequestCodeModal;
