import React, { useEffect, useState } from "react";
import theme from "../config/theme.json";
import AboutTab from "./AboutTab";
import ProjectTab from "./ProjectTab";

const TabsPanel = ({ selectedTab, openProjects, onChange, onClose }) => {
  const [previousLength, setPreviousLength] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  useEffect(() => {
    if (openProjects.length > 0) {
      if (openProjects.length > previousLength) {
        onChange(openProjects[openProjects.length - 1].id);
      } else {
        const s = openProjects.find((p) => p.id == selectedTab);
        if (!s) {
          onChange(
            openProjects[
              previousIndex < openProjects.length
                ? previousIndex
                : openProjects.length - 1
            ].id
          );
        }
      }
    } else {
      onChange(0);
    }
    setPreviousLength(openProjects.length);
  }, [openProjects.length]);

  useEffect(() => {
    const previousIndex = openProjects
      .map((p, i) => (p.id == selectedTab ? i : null))
      .filter((id) => id != null)[0];
    setPreviousIndex(previousIndex);
  }, [selectedTab]);

  useEffect(() => {
    const tabs = document.querySelectorAll(".tab");
    let previous;
    let selected;
    let next;
    for (let i = 0; i < tabs.length; i++) {
      if (selected) {
        next = tabs[i];
        break;
      }
      if (tabs[i].classList.contains("selected")) {
        selected = tabs[i];
      } else {
        previous = tabs[i];
      }
    }
    const scrollTarget = document.createElement("span");
    const tabBar = document.querySelector(".tab-bar");
    tabBar.insertBefore(scrollTarget, previous ?? selected);

    if (previous) scrollTarget.appendChild(previous);
    if (selected) scrollTarget.appendChild(selected);
    if (next) scrollTarget.appendChild(next);
    scrollTarget.scrollIntoView();
    if (previous) tabBar.insertBefore(previous, scrollTarget);
    if (selected) tabBar.insertBefore(selected, scrollTarget);
    if (next) tabBar.insertBefore(next, scrollTarget);
    tabBar.removeChild(scrollTarget);
  }, [selectedTab]);
  return (
    <div className="tabs-panel" style={{ borderColor: theme.borderColor }}>
      <div className="tab-bar">
        <div
          className={`tab ${selectedTab == 0 ? "selected" : ""}`}
          key="tab-hi"
          style={{
            borderBottomColor:
              selectedTab == 0 ? theme.backgroundColor : theme.borderColor,
            borderRightColor: theme.borderColor,
            borderTopColor: selectedTab == 0 ? theme.colorA : theme.borderColor,
            color: selectedTab != 0 ? theme.colorD : "",
          }}
          onClick={() => onChange(0)}
        >
          Hi, I'm{" "}
          <span
            style={{ color: selectedTab == 0 ? theme.colorB : theme.colorD }}
          >
            Jerry
          </span>
          !
        </div>
        {openProjects.map((project) => (
          <div
            className={`tab ${selectedTab == project.id ? "selected" : ""}`}
            key={`tab-${project.id}`}
            style={{
              borderBottomColor:
                selectedTab == project.id
                  ? theme.backgroundColor
                  : theme.borderColor,
              borderRightColor: theme.borderColor,
              borderTopColor:
                selectedTab == project.id ? theme.colorA : theme.borderColor,
              color: selectedTab != project.id ? theme.colorD : "",
            }}
            onClick={(e) => {
              if (!e.target.classList.contains("tab-close")) {
                onChange(project.id);
              }
            }}
          >
            {project.name}
            <span className="tab-close" onClick={() => onClose(project)}>
              x
            </span>
          </div>
        ))}
      </div>
      <div className="tab-content" style={{ borderColor: theme.borderColor }}>
        {selectedTab == 0 ? (
          <AboutTab />
        ) : (
          <ProjectTab
            project={
              openProjects.filter((project) => project.id == selectedTab)[0]
            }
          />
        )}
      </div>
    </div>
  );
};

export default TabsPanel;
