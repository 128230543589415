import React from "react";
import LayoutBox from "./LayoutBox";
import { useState } from "react";
import LightBox from "./LightBox";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../config/theme.json";

const MediaGallery = ({ media }) => {
  const [selectedImage, setSelectedImage] = useState();
  return (
    <>
      <LayoutBox title="Media Gallery">
        {media.map((item) => {
          const url = item.url.includes("youtube")
            ? `https://i3.ytimg.com/vi/${
                item.url.split("?v=")[1]
              }/hqdefault.jpg`
            : item.url;
          return (
            <div
              className="media-gallery-item"
              style={{ backgroundImage: `url(${url})` }}
              onClick={() => setSelectedImage(item)}
            >
              {item.url.includes("youtube") && (
                <span className="play-icon">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{
                      color: theme.colorA,
                      fontSize: "3em",
                    }}
                  />
                </span>
              )}
            </div>
          );
        })}
      </LayoutBox>
      {selectedImage && (
        <LightBox
          media={selectedImage}
          orientation={selectedImage.orientation}
          onClose={() => setSelectedImage("")}
        />
      )}
    </>
  );
};

export default MediaGallery;
