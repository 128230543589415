import React from "react";
import ButtonBar from "./ButtonBar";
import { faWarning, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../context/AuthContext";
import MediaGallery from "./MediaGallery";
import theme from "../config/theme.json";

const ProjectTab = ({ project }) => {
  const { isAuthenticated } = useAuth();
  if (!project) return null;
  const buttons = [];
  if (project.source) {
    buttons.push({
      label: (
        <>
          {!isAuthenticated && (
            <FontAwesomeIcon
              icon={faLock}
              style={{
                fontSize: "0.7em",
                marginRight: "9px",
                verticalAlign: "middle",
                marginTop: "-5px",
              }}
            />
          )}
          View Source
        </>
      ),
      projectId: project.source,
      requiresAuth: true,
    });
  }
  if (project.demo) {
    buttons.push({ label: "Try Demo", url: project.demo });
  }
  if (project.demoDesktop) {
    buttons.push({ label: "Try Demo (PC)", url: project.demoDesktop });
  }
  if (project.demoAndroid) {
    buttons.push({ label: "Try Demo (Android)", url: project.demoAndroid });
  }
  if (project.demoApple) {
    buttons.push({ label: "Try Demo (iOS)", url: project.demoApple });
  }
  if (project.website) {
    buttons.push({ label: "Visit Site", url: project.website });
  }
  return (
    <>
      {project.deprecated && (
        <ul style={{ marginTop: "-25px" }}>
          <li>
            <FontAwesomeIcon icon={faWarning} style={{ color: theme.colorA }} />
            I am no longer the maintainer of this project, so I make no
            guarantee of the working condition of the demo.
          </li>
        </ul>
      )}
      <p>{project.content}</p>
      {buttons.length > 0 && <ButtonBar buttons={buttons} />}
      {project.media && <MediaGallery media={project.media} />}
    </>
  );
};

export default ProjectTab;
