import React from "react";
import LayoutBox from "./LayoutBox";

const LightBox = ({ media, onClose }) => {
  return (
    <>
      <div className="dim" onClick={onClose} />
      <div
        className={
          "modal lightbox" +
          ((media.url.includes("youtube") ? " video" : "") +
            (media.orientation == "vertical" ? " vertical" : ""))
        }
      >
        <LayoutBox onClose={onClose}>
          {media.url.includes("youtube") ? (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${
                media.url.split("?v=")[1]
              }?rel=0`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          ) : (
            <img src={media.url} />
          )}
        </LayoutBox>
      </div>
    </>
  );
};

export default LightBox;
