import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodePullRequest } from "@fortawesome/free-solid-svg-icons";
import theme from "../config/theme.json";

const BulletedList = ({ items }) => {
  return (
    <ul>
      {items.map((item) => (
        <li>
          <FontAwesomeIcon
            icon={faCodePullRequest}
            style={{ color: theme.borderColor }}
          />
          {item}
        </li>
      ))}
    </ul>
  );
};

export default BulletedList;
