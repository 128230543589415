import React from "react";
import ProjectItem from "./ProjectItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";
import theme from "../config/theme.json";

const ProjectsPanel = ({
  showingMobile,
  projects,
  selectedTab,
  openProjects,
  onOpen,
}) => {
  return (
    <div
      className={`projects-panel ${showingMobile ? "open" : ""}`}
      style={{
        backgroundColor: theme.lighterBackgroundColor,
        borderColor: theme.borderColor,
      }}
    >
      <div className="panel-title">
        <FontAwesomeIcon icon={faGitAlt} style={{ color: theme.colorA }} />
        My Projects
      </div>
      <div className="project-list" style={{ borderColor: theme.borderColor }}>
        {projects.map((project, index) => (
          <ProjectItem
            key={`project-${project.id}`}
            project={project}
            onClick={onOpen}
            selected={selectedTab == project.id}
            open={
              openProjects?.filter((p) => p.name == project.name).length > 0
            }
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectsPanel;
