import React from "react";
import Tag from "./Tag";
import theme from "../config/theme.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFolderOpen } from "@fortawesome/free-regular-svg-icons";

const ProjectItem = ({ project, selected, open, onClick }) => {
  return (
    <div
      className="project"
      style={{ borderColor: theme.borderColor }}
      onClick={() => {
        if (onClick) onClick(project);
      }}
    >
      <FontAwesomeIcon
        icon={open ? faFolderOpen : faFolder}
        style={{ color: open && selected ? theme.colorA : theme.colorD }}
        className="folder-icon"
      />
      <div className="project-details">
        <div className="project-name">{project.name}</div>
        <div className="project-subtitle" style={{ color: theme.colorD }}>
          {project.subtitle}
        </div>
        <div className="project-tags">
          {project.source && <Tag name="src" />}
          {(project.demo ||
            project.demoDesktop ||
            project.demoAndroid ||
            project.demoApple) && <Tag name="demo" />}
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
