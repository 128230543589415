import React from "react";
import theme from "../config/theme.json";

const LayoutBox = ({ title, children, onClose }) => {
  return (
    <div
      className="layout-box"
      style={{
        borderTopColor: theme.colorA,
        borderRightColor: theme.borderColor,
        borderLeftColor: theme.borderColor,
        borderBottomColor: theme.borderColor,
      }}
    >
      <div className="panel-title">
        {title}
        {onClose && (
          <span className="tab-close" onClick={onClose}>
            x
          </span>
        )}
      </div>
      <div className="panel-content">{children}</div>
    </div>
  );
};

export default LayoutBox;
